import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/markdown-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`PRIVACY POLICY`}</h1>
    <p>{`Armada Konferencije doo`}</p>
    <p>{`Last updated: May 15th, 2023`}</p>
    <p>{`This Privacy Policy (“Privacy Policy“) describes and summarizes the policies and procedures of Armada Konferencije doo Services
(“AK“, “we“, “our” or “us“) with respect to the processing of personal data (“Personal Data“) provided or acquired through use of our website located at `}<a parentName="p" {...{
        "href": "https://armada-js.com/"
      }}>{`https://armada-js.com/`}</a>{`
and all related subdomains (collectively, the “Website“) and all content, features, services and/or products available on or through the Website (collectively, the AK Services“).`}</p>
    <p>{`AK takes the privacy of individuals very seriously. We are committed to maintaining the security, confidentiality, and integrity of the personal data in our custody or control and protect such data in accordance with the applicable legislation.
AK regularly re-evaluates its privacy and security practices and adapts them as necessary to deal with new regulatory requirements, changes in legislation, its Services and/or security standards.`}</p>
    <h3>{`DATA WE COLLECT`}</h3>
    <p>{`Whenever we collect Personal Data from you, you will be informed, and you will be able to access the precise following information:`}</p>
    <ul>
      <li parentName="ul">{`personal data we have collected from you;`}</li>
      <li parentName="ul">{`the basis on which it is retained (e.g. because you gave us consent);`}</li>
      <li parentName="ul">{`what will be done with it;`}</li>
      <li parentName="ul">{`how long it will be held for;`}</li>
      <li parentName="ul">{`who it might be shared with;`}</li>
      <li parentName="ul">{`your rights in relation to your Personal Data;`}</li>
      <li parentName="ul">{`information on how you can access and manage Personal Data.`}</li>
    </ul>
    <p>{`We have provided further detail below about the specific types of Personal Data we collect and our reasons for doing so.`}</p>
    <h4>{`Whose Personal Data Do We Collect?`}</h4>
    <p>{`We collect Personal Data about:`}</p>
    <ul>
      <li parentName="ul">{`our Website visitors (“Visitors”);`}</li>
      <li parentName="ul">{`people who use our Services (“Clients” and “Users”).`}</li>
    </ul>
    <h4>{`What Personal Data Do We Ask You to Provide to Us, and Why?`}</h4>
    <p>{`AK typically collects the following categories of Personal Data:`}</p>
    <ul>
      <li parentName="ul">{`biographical data such as first and last name, phone number;`}</li>
      <li parentName="ul">{`contact data such as address and email address;`}</li>
      <li parentName="ul">{`any other Personal Data that you may decide to share with us, for example, such as communication and support data that you share when you contact us.`}</li>
    </ul>
    <p>{`When you access our Websites whether by computer, mobile phone or any other device, we automatically collect certain information about your use of our Websites which may include,
among other things: geographical location and IDs of the device; bandwidth used; system and connection performance; browser type and version; operating system; referral source; length of visit; page views; IP address or other unique identifier of your device.`}</p>
    <p>{`Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual person.`}</p>
    <h4>{`How We Can Use Your Personal Data?`}</h4>
    <p>{`AK may use the collected Personal Data for the following purposes:`}</p>
    <ul>
      <li parentName="ul">{`to provide, maintain, administer, support, protect and improve the Services;`}</li>
      <li parentName="ul">{`to provide customer support;`}</li>
      <li parentName="ul">{`to allow our partners to provide their services;`}</li>
      <li parentName="ul">{`to manage and administer your account;`}</li>
      <li parentName="ul">{`to handle and process your inquiries;`}</li>
      <li parentName="ul">{`to send newsletters to you;`}</li>
      <li parentName="ul">{`to process your payments;`}</li>
      <li parentName="ul">{`to respond to your requests for exercising your privacy rights under the applicable data protection laws;`}</li>
      <li parentName="ul">{`to enforce compliance with the Terms of Service;`}</li>
      <li parentName="ul">{`to investigate any illegal activity or wrongdoing in connection with the Services;`}</li>
      <li parentName="ul">{`to carry out AK's legal obligations;`}</li>
      <li parentName="ul">{`to establish compliance with the data protection laws during an audit or inspection conducted by an appropriate data protection authority.`}</li>
    </ul>
    <h4>{`Who Do We Share Your Personal Data With?`}</h4>
    <p>{`We may share your Personal Data with certain third-party service providers for the purposes of facilitating the provision of our Services.
Your Personal Data will also be shared with our partners for the purposes of delivering their services you have requested them to you.
Some of our partners who receive your Personal Data may be located in countries outside of the EU that do not offer an adequate level of protection of personal data as determined by the European Commission.
We require that transfers of your Personal Data to such partners are subject to appropriate safeguards.
Such safeguards include contractual arrangements with our partners, such as the standard contractual clauses approved by the European Commission.`}</p>
    <p>{`There are certain situations in which we may share access to your Personal Data, for example:`}</p>
    <ul>
      <li parentName="ul">{`if required by law, to protect the life of an individual;`}</li>
      <li parentName="ul">{`to comply with any valid legal process, government request, rule or regulation;`}</li>
      <li parentName="ul">{`in case of sale or transfer all or a portion of our company's business interests, assets, or both, or in connection with a corporate merger, consolidation, restructuring, or other company change;`}</li>
      <li parentName="ul">{`to our subsidiaries or affiliates only if necessary for business and operational purposes as described in the section above.`}</li>
    </ul>
    <h3>{`USE OF COOKIES`}</h3>
    <p>{`A cookie is a small text file which includes a unique identifier that is sent by a web server to the browser on your computer, mobile phone or any other internet enabled device when you visit the Website.
Cookies and similar technologies are widely used to make websites work efficiently and to collect information about your online preferences.
For simplicity, we refer to all these technologies as “cookies”.`}</p>
    <p>{`Some of our website pages may contain electronic images known as web beacons (also known as clear gifs, tags, or pixels) that allow us to count users who have visited our pages.
Web beacons collect only limited information, e.g. a cookie number, time and date of a page view, and a description of the page on which the web beacon resides.
We may also carry web beacons placed by third party advertisers. These beacons do not carry any information that could directly identify you.`}</p>
    <p>{`We use the following types of cookies on our Websites:`}</p>
    <p>{`Strictly Necessary cookies: These cookies are strictly necessary to provide you with services available through our websites;
Performance and functionality cookies: These cookies are used to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality may become unavailable;
Analytics and customization cookies: These cookies collect information that is used either in aggregate form to help us understand how our websites are being used or how effective our marketing campaigns are, or to help us customize our websites for you.`}</p>
    <h3>{`HOW DO WE KEEP YOUR PERSONAL DATA SECURE?`}</h3>
    <p>{`We keep your Personal Data secure:`}</p>
    <ul>
      <li parentName="ul">{`by implementing and maintaining commercially reasonable measures to protect against unauthorized access to and unlawful interception or processing of Personal Data we process;`}</li>
      <li parentName="ul">{`by following internal policies of best practice and training for staff.`}</li>
    </ul>
    <h3>{`CHANGES TO OUR PRIVACY POLICY?`}</h3>
    <p>{`We may change this Privacy Policy from time to time.
When we do, we will let you know by adding notices to our Websites.
We will also update the “Last Updated” section at the beginning of this document.`}</p>
    <h3>{`CONTROLLER AND PROCESSOR`}</h3>
    <p>{`In relation to Clients and Users, AK acts as Controller and as a Processor of their Personal Data.`}</p>
    <p>{`AK shall adhere to the following general principles with respect to Personal Data processing:`}</p>
    <ul>
      <li parentName="ul">{`not to collect more Personal Data than is necessary for the purpose of providing the Services;`}</li>
      <li parentName="ul">{`not to use Personal Data for any other purposes than those specified in this Privacy Policy;`}</li>
      <li parentName="ul">{`to ensure that all employees authorized by AK to process Personal Data have committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality;`}</li>
      <li parentName="ul">{`not to knowingly solicit, access, collect and/or process any sensitive categories of Personal Data.`}</li>
    </ul>
    <h3>{`LEGAL BASIS FOR PROCESSING`}</h3>
    <p>{`Where AK acts as a Controller, AK's legal basis for processing the Personal Data will depend on the purposes of the processing.
AK will collect and process Personal Data based on the following legal bases:`}</p>
    <ul>
      <li parentName="ul">{`processing is necessary for the performance of a contract to which the User is a party, particularly for the provision of the Services;`}</li>
      <li parentName="ul">{`consent (e.g. to send you newsletters via email);`}</li>
      <li parentName="ul">{`processing is necessary for compliance with a legal obligation to which AK is subject;`}</li>
      <li parentName="ul">{`processing is necessary for the purposes of our legitimate interests such as marketing, troubleshooting and fixing errors on our Website or improving our Services.`}</li>
    </ul>
    <p>{`If there is another legal basis for AK to collect and process Personal Data, AK will provide the required notification to User at or before the time the Personal Data is collected.`}</p>
    <h3>{`THIRD PARTY WEBSITES`}</h3>
    <p>{`The Services may include links to, or otherwise direct User's attention towards, websites operated by third parties and not by AK.
Such links are provided solely for User's convenience and informational purposes.
The inclusion of any link does not imply an association, support, endorsement, consent, examination, or approval by AK of such third party and third-party website (including without limitation any content on such website).`}</p>
    <h3>{`YOUR PRIVACY RIGHTS`}</h3>
    <p>{`If we process your Personal Data, you may have the following privacy rights:`}</p>
    <ul>
      <li parentName="ul">{`the right to be informed about the collection and use of your Personal Data;`}</li>
      <li parentName="ul">{`the right of access to your Personal Data and any supplementary information;`}</li>
      <li parentName="ul">{`the right to have any errors in your Personal Data rectified;`}</li>
      <li parentName="ul">{`the right to have your Personal Data erased;`}</li>
      <li parentName="ul">{`the right to block or suppress the processing of your Personal Data;`}</li>
      <li parentName="ul">{`the right to move, copy or transfer your Personal Data from one IT environment to another;`}</li>
      <li parentName="ul">{`the right withdraw your consent;`}</li>
      <li parentName="ul">{`the right to object to our processing of your Personal Data in certain circumstances.`}</li>
    </ul>
    <p>{`We retain Personal Data for the duration of your relationship with us and 3 years after we cease to provide our Services to you.`}</p>
    <p>{`You have the right to submit a complaint to the data protection authority in the member state of your habitual residence, place or work or the alleged violation of your rights under the GDPR.`}</p>
    <p>{`If you would like to get in touch about anything in this policy or about your Personal Data then please contact us at: Armada Konferencije doo, e-mail: `}<a parentName="p" {...{
        "href": "mailto:contact@armada-js.com"
      }}>{`contact@armada-js.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      